@import '../../styles/customMediaQueries.css';

.root {
    margin: 0 15px 56px 15px;

    @media (--viewportMedium) {
        padding: 0 24px 0 24px;
        margin: 0 auto 56px auto;
    }

    @media (--viewportLarge) {
        /* width: min(calc(var(--contentMaxWidth) + 72px), 100vw); */
        width: 100%;
        padding: 80px 80px 0 80px;
        margin: 0 auto 90px auto;
    }
}

.listingsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;

    @media (--viewportLarge) {
        grid-template-columns: repeat(6, 1fr);
        gap: 30px;
    }
}



.header {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    margin-bottom: 30px;
    gap: 20px;

    @media (--viewportMedium) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #E6E6E6;
    }
}

.title {
    font-weight: 400;
    color: var(--colorBlack);
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2.08px;
    margin: 0;
    padding: 0;

}

.linkWrapper {
    display: flex;

    @media (--viewportMedium) {
        justify-content: center;
        align-items: center;
    }
}

.link {
    color: #808080;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1.6px;
    font-weight: 500;
    text-decoration: underline;
    text-transform: uppercase;
    transition: var(--transitionStyleButton);

    &:hover {
        color: var(--marketplaceColor);
        text-decoration: underline;
    }
}